import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/dashboard.scss";
import "../css/searchPage.scss";
import "../css/stylesnan.scss";

import { useLocation, useNavigate } from "react-router-dom";

// Utility function to format dates as YYYY-MM-DD
const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function Dashboard() {
  const [members, setMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage] = useState(20);
  const [query, setQuery] = useState("");
  const [couponUsed, setCouponUsed] = useState(0);
  const [insuranceEligible, setInsuranceEligible] = useState(0);
  const [mostsellDistrict, setMostsellDistrict] = useState([]);
  const [leastsellDistrict, setLeastsellDistrict] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [filepath, setFilepath] = useState(""); // Add state to store filepath

  const navigate = useNavigate();
  const location = useLocation();

  const email = location.state || {
    email: localStorage.getItem("admin_mail"),
    useID: localStorage.getItem("user_id"),
  };

  const userId = email.useID;
  const admin_mail = email.email;

  useEffect(() => {
    if (email.email) localStorage.setItem("admin_mail", email.email);
    if (email.useID) localStorage.setItem("user_id", email.useID);
  }, [email]);

  const lastPostIndex = currentPage * membersPerPage;
  const firstPostIndex = lastPostIndex - membersPerPage;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchMembers();
  }, [startDate]); // Fetch members when startDate changes

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/dashboard",
        { todaydate: formatDate(startDate), format: "excel" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMembers(response.data.data);
      setCouponUsed(response.data.redeemed_code || 0);
      setInsuranceEligible(response.data.insurance_eligible || 0);
      setMostsellDistrict(response.data.most_selled_in || []);
      setLeastsellDistrict(response.data.least_selled_in || []);
      setFilepath(response.data.filepath); // Store filepath from API response
    } catch (error) {
      console.error(error);
    }
  };

  const filteredMembers = members?.filter((member) => {
    const name = member.u_name ? String(member.u_name).toLowerCase() : "";
    const adhaar = member.u_adhaar ? String(member.u_adhaar).toLowerCase() : "";
    const occupation = member.u_occupation
      ? String(member.u_occupation).toLowerCase()
      : "";
    const queryLowerCase = query.toLowerCase();
    const matchesQuery =
      name.includes(queryLowerCase) ||
      adhaar.includes(queryLowerCase) ||
      occupation.includes(queryLowerCase);

    const memberDate = new Date(member.u_joining_date);
    const matchesDate = !startDate || memberDate >= startDate;

    return matchesQuery && matchesDate;
  });

  // Set totalPages to 50 to always show 50 pages
  const totalPages = 50;

  const handleDownload = () => {
    if (filepath) {
      const link = document.createElement("a");
      link.href = filepath;
      link.download = filepath.split("/").pop(); // Get the file name from the path
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("File not available for download.");
    }
  };

  return (
    <Layout>
      <div className="status-box">
        <div className="status-active">
          <h6>{couponUsed}</h6>
          <h5>Coupon Used</h5>
        </div>
        <div className="status-inactive">
          <h6>{insuranceEligible}</h6>
          <h5>Eligible for Insurance</h5>
        </div>
        {mostsellDistrict?.map((item) => (
          <div className="status-inactive" key={item.u_district}>
            <h6>{item.total_sales}</h6>
            <h5>Highest Sales: {item.u_district}</h5>
          </div>
        ))}
        {leastsellDistrict?.map((item) => (
          <div className="status-inactive" key={item.u_district}>
            <h6>{item.total_sales}</h6>
            <h5>Lowest Sales: {item.u_district}</h5>
          </div>
        ))}
      </div>

      <div className="filter-box">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Select a date"
          className="date-picker"
          dateFormat="yyyy-MM-dd"
        />
      </div>

      <div className="search-box">
        <FaSearch id="search-icon" />
        <input
          type="text"
          id="search"
          placeholder="Search member referral ID"
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
        />
      </div>

      <div className="mt-5" id="tab">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Aadhar Number</th>
                <th scope="col">Occupation</th>
              </tr>
            </thead>
            <tbody>
              {filteredMembers
                ?.slice(firstPostIndex, lastPostIndex)
                ?.map((member, index) => (
                  <tr className="line1px" key={member.u_id}>
                    <th scope="row">{firstPostIndex + index + 1}</th>
                    <td>{member.u_name}</td>
                    <td>{member.u_adhaar}</td>
                    <td>{member.u_occupation}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="secondary"
        className="mt-3"
      />
      <div className="download-but">
        <button onClick={handleDownload} className="">
          Download File
        </button>
      </div>
    </Layout>
  );
}

export default Dashboard;
