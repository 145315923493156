import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import "../css/stylesnan.scss";
import axios from "axios";

function Couponcount() {
  const [members, setMembers] = useState([]);
  const [couponCount, setCouponcount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/dashboard",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMembers(response.data.data); // Ensure that members is an array
      setCouponcount(response.data.generated_code);
    } catch (error) {
      console.error(error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = couponCount?.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading 0 if needed
    const day = String(date.getDate()).padStart(2, "0"); // Add leading 0 if needed
    return `${year}-${month}-${day}`;
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':'); // Extract hours and minutes
    return `${hours}:${minutes}`; // Format as HH:MM
  };
  return (
    <Layout>
      <div className="text-center">
        <h1>Coupon Count</h1>
      </div>

      <div className="mt-5" id="tab">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Coupon Date</th>
                <th scope="col">Coupon Time</th>

                <th scope="col">Coupon Count</th>
                <th scope="col">Item</th>
                <th scope="col">Code</th>
                <th scope="col">Quantity</th>
                <th scope="col">Type</th>
                <th scope="col">Prefix</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item, index) => (
                <tr className="line1px" key={item.id}>
                  <th scope="row">{indexOfFirstItem + index + 1}</th>
                  <td>{formatDate(item.c_date)}</td>
                  <td>{formatTime(item.c_time)}</td>

                  <td>{item.c_count}</td>
                  <td>{item.p_item}</td>
                  <td>{item.p_code}</td>
                  <td>
                    {item.p_quantity} {item.p_unit}
                  </td>
                  <td>{item.p_type}</td>
                  <td>{item.p_prefix}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default Couponcount;
