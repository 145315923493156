import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import "../css/stylesnan.scss";
import { Pagination, Modal, Button, Form, Alert } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import axios from "axios";

function Packages() {
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    p_item: "",
    p_code: "",
    p_brand: "",
    p_quantity: "",
    p_unit: "",
    p_type: "",
    p_prefix: "",
  });
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchOptions();
  }, [currentPage]);

  const fetchOptions = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/list-packages"
      );
      setList(response.data.data);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch packages. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      await axios.post(
        "https://lunarsenterprises.com:3006/tea/delete-packages",
        {
          p_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            user_id: user_id,
          },
        }
      );
      alert("Package deleted successfully.");
      fetchOptions(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting package:", error);
      setError("Failed to delete package. Please try again.");
    }
  };

  const handleAddPackage = async () => {
    const { p_item, p_code, p_quantity, p_unit } = formData;
    if (!p_item || !p_code || !p_quantity || !p_unit) {
      setError("Please fill in all required fields.");
      return;
    }
    try {
      const token = localStorage.getItem("user_token");
      const user_id = localStorage.getItem("user_id");

      await axios.post(
        "https://lunarsenterprises.com:3006/tea/add-packages",

        formData, // Spread formData to include all its properties at the root level of the request body

        {
          headers: {
            Authorization: `Bearer ${token}`,
            user_id: user_id,
          },
        }
      );
      alert("Package added successfully");
      setShowModal(false); // Close modal after successful submission
      fetchOptions(); // Refresh the list after adding the package
    } catch (error) {
      console.error("Error adding package:", error);
      setError("Failed to add package. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null); // Clear error on input change
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Layout>
      <div className="text-center">
        <h1>Packages</h1>
      </div>
      <div className="date-search">
        <div className="dates">{/* Date filter can be added here */}</div>
        <Button onClick={() => setShowModal(true)}>Add Packages</Button>
      </div>
      <div className="mt-5" id="tab">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          {error && <Alert variant="danger">{error}</Alert>}
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">ITEM</th>
                <th scope="col">CODE</th>
                <th scope="col">QUANTITY</th>
                <th scope="col">TYPE</th>
                <th scope="col">PREFIX</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr className="line1px" key={item.id}>
                  <th scope="row">{indexOfFirstItem + index + 1}</th>
                  <td>{item.p_item}</td>
                  <td>{item.p_code}</td>
                  <td>
                    {item.p_quantity} {item.p_unit}
                  </td>
                  <td>{item.p_type}</td>
                  <td>{item.p_prefix}</td>
                  <td>
                    <Button
                      className="btn btn-danger"
                      onClick={() => handleDelete(item.p_id)}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination className="mt-3">
        {[...Array(Math.ceil(list.length / itemsPerPage)).keys()].map(
          (number) => (
            <Pagination.Item
              key={number + 1}
              active={number + 1 === currentPage}
              onClick={() => handlePageChange(number + 1)}
            >
              {number + 1}
            </Pagination.Item>
          )
        )}
      </Pagination>

      {/* Modal for Adding Package */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="p_item">
              <Form.Label>Item</Form.Label>
              <Form.Control
                type="text"
                name="p_item"
                value={formData.p_item}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_code" className="mt-3">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                name="p_code"
                value={formData.p_code}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_brand" className="mt-3">
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type="text"
                name="p_brand"
                value={formData.p_brand}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_quantity" className="mt-3">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                name="p_quantity"
                value={formData.p_quantity}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_unit" className="mt-3">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                name="p_unit"
                value={formData.p_unit}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_type" className="mt-3">
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                name="p_type"
                value={formData.p_type}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="p_prefix" className="mt-3">
              <Form.Label>Prefix</Form.Label>
              <Form.Control
                type="text"
                name="p_prefix"
                value={formData.p_prefix}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddPackage}>
            Add Package
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default Packages;
