import Layout from "../Component/Layout";

const Registered = () => {
  return (
    <Layout>
      <div className="text-center">
        <h1>Registered Users</h1>
      </div>
      <div className="mt-5" id="tab">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Aadhar Number</th>
                <th scope="col">Occupation</th>
              </tr>
            </thead>
            <tbody>
                
                  <tr className="line1px" >
                    <th scope="row">sdkmfd</th>
                    <td>order.u_first_name</td>
                    <td>order.u_mobile</td>
                    <td>order.u_referal_id</td>
                  </tr>
                
              
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Registered;
