import { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import "../css/Coupon.scss";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import couponImage from "../image/banner.jpg";
import axios from "axios";

function Coupon() {
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [option, setOption] = useState("Coupon Code");
  const [format, setFormat] = useState("pdf"); // State to hold the selected format
  const [optionsList, setOptionsList] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const handleChange = (event) => {
    setCoupon(event.target.value);
  };

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };

  const fetchOptions = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/list-packages"
      );
      console.log(response.data.data);

      if (Array.isArray(response.data.data)) {
        const filteredOptions = response.data.data.filter((option) => {
          return option.p_prefix;
        });
        setOptionsList(filteredOptions);
      } else {
        console.error("Unexpected API response format:", response.data);
      }
      setLoadingOptions(false);
    } catch (error) {
      console.log(error);
      setLoadingOptions(false);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const handleGenerate = async () => {
    setLoading(true);
    console.log("Starting handleGenerate...");
    try {
      console.log("Before GenerateCode call...");

      const selectedOption = optionsList.find(
        (option) => option.p_prefix === coupon
      );
      if (!selectedOption) {
        throw new Error("Selected option not found");
      }

      const fileUrl = await GenerateCode(selectedOption.p_id);
      console.log("After GenerateCode call...");

      setDownload(true);
      setDownloadUrl(fileUrl);

      notify(`Generated ${option}: ${coupon}`);
    } catch (error) {
      console.error("Error generating code:", error);
      notify("Error generating code");
    } finally {
      setLoading(false);
    }
  };

  const GenerateCode = async (p_id) => {
    console.log("Starting GenerateCode with p_id:", p_id);

    try {
      const token = localStorage.getItem("user_token");
      console.log("Token retrieved:", token);

      if (!token) {
        console.error("No token found in localStorage.");
        throw new Error("Authorization token is missing.");
      }

      console.log("Before making API call...");

      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/generate/redeemcode",
        { p_id },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob", // Expecting a file in the response
        }
      );

      console.log("API call succeeded, response:", response.data);

      // Create a download URL from the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      return url;
    } catch (error) {
      console.error("Error during API call:", error.message);
      console.error("Error details:", error);
      throw error;
    }
  };

  const handleDownload = async () => {
    if (!coupon || !option) {
      notify("Please select an option and generate the code first.");
      return;
    }

    setLoadingDownload(true);

    try {
      const token = localStorage.getItem("user_token");
      if (!token) {
        notify("Authorization token is missing.");
        return;
      }

      const selectedOption = optionsList.find(
        (option) => option.p_prefix === coupon
      );
      if (!selectedOption) {
        notify("Selected option not found.");
        return;
      }

      const apiUrl =
        option === "QR Code"
          ? "https://lunarsenterprises.com:3006/tea/download/qrcode/redeemcode"
          : "https://lunarsenterprises.com:3006/tea/download/coupon/redeemcode";

      // Include the selected format in the API request body
      const response = await axios.post(
        apiUrl,
        { format }, // Pass the selected format here
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data && response.data.result) {
        const filepath = response.data.filepath;

        const link = document.createElement("a");
        link.href = filepath;
        link.setAttribute("download", `${coupon}_${option}.${format}`); // Set the filename with the correct extension
        document.body.appendChild(link);
        link.click();
        link.remove();

        notify("File downloaded successfully!, Now you can take print ");
      } else {
        notify("Failed to download file");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      notify("Error downloading file");
    } finally {
      setLoadingDownload(false);
    }
  };

  const notify = (message) => toast(message);

  return (
    <Layout>
      <div className="text-center">
        <h3>Coupon Generate</h3>
      </div>

      <div className="coupon-select">
        <Box sx={{ minWidth: 120 }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel id="option-select-label">Select Option</InputLabel>
            <Select
              labelId="option-select-label"
              id="option-select"
              value={option}
              onChange={handleOptionChange}
              label="Option"
            >
              <MenuItem value="Coupon Code">Coupon Code</MenuItem>
              <MenuItem value="QR Code">QR Code</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Generate {option}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={coupon}
              onChange={handleChange}
              label={`Generate ${option}`}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {loadingOptions ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : (
                optionsList?.map((item, index) => (
                  <MenuItem key={index} value={item.p_prefix}>
                    {item.p_prefix}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          {download && (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="format-select-label">Select Format</InputLabel>
              <Select
                labelId="format-select-label"
                id="format-select"
                value={format}
                onChange={handleFormatChange}
                label="Format"
              >
                <MenuItem value="pdf">PDF</MenuItem>
                <MenuItem value="excel">Excel</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>

        <button onClick={handleGenerate} id="gen-but">
          {loading ? <CircularProgress size={24} /> : `Generate ${option}`}
        </button>
      </div>
      {download && (
        <div className="coup-dow">
          <button onClick={handleDownload}>
            {loadingDownload ? (
              <CircularProgress size={24} />
            ) : (
              `Download ${option}`
            )}
          </button>
        </div>
      )}
      <img src={couponImage} alt="" id="ban-image" />

      <ToastContainer />
    </Layout>
  );
}

export default Coupon;
