import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../src/css/main.scss";
import "../src/css/bootstrap.min.css";
import Sidebar from "./Component/Sidebar";
import Header from "./Component/Header";
import Dashboard from "./Screens/Dashboard";
import SignIn from "./Screens/Signin";
import AppState from "./context/Context";
import ProductManagement from "./Screens/Coupongenerate";
import Addproduct from "./Screens/Registered";
import Productlist from "./Screens/Insurance";
import Packages from "./Screens/Packages";
import Couponcount from "./Screens/Couponcount";


function Main() {
  const location = useLocation();
  const currPath = location.pathname;

  return (
    <div className="">
      <div>
        {currPath === "/SignIn" ? (
          <SignIn />
        ) : (
          <>
            <Sidebar />
            <Header />
          </>
        )}
      </div>

      <div className="">
        <Routes>
          <Route path="/" name="SignIn" element={<Navigate to="/SignIn" />} />
          <Route path="/DashBoard" element={<Dashboard />} />
          <Route path="/income_generate" element={<ProductManagement />} />
          <Route path="/registered_user" element={<Addproduct />} />
          <Route path="/insurance_claimed" element={<Productlist />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/couponcount" element={<Couponcount />} />

        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <AppState>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
     
    </AppState>
  );
}

export default App;
