import Logo from "../image/logo-s.png";
import { useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import { HiBars3 } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currPath = location.pathname;
  const { logindetsils } = useContext(Context);
  const [showMobileView, setShowMobileView] = useState(false);
  const sidebarRef = useRef(null); // Reference to the sidebar

  const toggleMobileView = () => {
    setShowMobileView(!showMobileView);
  };

  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowMobileView(false); // Close mobile view if clicked outside
    }
  };

  useEffect(() => {
    if (showMobileView) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showMobileView]);

  const logout = () => {
    localStorage.removeItem("user_token");
    navigate("/");
  };

  return (
    <>
      <div className="mobile-menu" onClick={toggleMobileView}>
        <HiBars3 />
      </div>
      <div className="cx-sidebar-main" style={{ zIndex: 1 }}>
        <div className="mobile-menu" onClick={toggleMobileView}>
          <HiBars3 />
        </div>
        <div
          className="sidebar d-flex flex-column justify-content-between sidecontent"
          ref={sidebarRef}
        >
          <div>
            <div className="p-4 logomaindiv">
              <a href="/dashboard">
                <i className="fs-5">
                  <img
                    src={Logo}
                    alt="logo"
                    className="blacklogoimage"
                    style={{ width: 150, height: 100 }}
                  />
                </i>
              </a>
            </div>
            <div className="menu-wrapper">
              <div className="menus">
                <div
                  className={
                    currPath === "/DashBoard" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/DashBoard")}
                >
                  Dashboard
                </div>
                <div
                  className={
                    currPath === "/income_generate"
                      ? "menu active-menu"
                      : "menu"
                  }
                  onClick={() => navigate("/income_generate")}
                >
                  Coupon generate
                </div>
                <div
                  className={
                    currPath === "/insurance_claimed"
                      ? "menu active-menu"
                      : "menu"
                  }
                  onClick={() => navigate("/insurance_claimed")}
                >
                  Top achivers
                </div>
                <div
                  className={
                    currPath === "/packages" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/packages")}
                >
                  Packages
                </div>
                <div
                  className={
                    currPath === "/couponcount" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/couponcount")}
                >
                  Coupon Count
                </div>
                <div
                  className="logout"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    zIndex: 100,
                    marginTop: "auto"  // This pushes LOG OUT to the bottom
                  }}
                  onClick={logout}
                >
                  LOG OUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`mobile-view ${showMobileView ? "visible" : "hidden"}`}>
        <div className="mobile-men" onClick={toggleMobileView}>
          <IoMdClose />
        </div>
        <div
          className="sidebar d-flex flex-column justify-content-between sidecontent"
          ref={sidebarRef}
        >
          <div>
            <div className="p-4 logomaindiv">
              <a href="/dashboard">
                <i className="fs-5">
                  <img
                    src={Logo}
                    alt="logo"
                    className="blacklogoimage"
                    style={{ width: 150, height: 100 }}
                  />
                </i>
              </a>
            </div>
            <div className="menu-wrapper">
              <div className="menus">
                <div
                  className={
                    currPath === "/DashBoard" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/DashBoard")}
                >
                  Dashboard
                </div>
                <div
                  className={
                    currPath === "/income_generate"
                      ? "menu active-menu"
                      : "menu"
                  }
                  onClick={() => navigate("/income_generate")}
                >
                  Coupon generate
                </div>

                <div
                  className={
                    currPath === "/insurance_claimed"
                      ? "menu active-menu"
                      : "menu"
                  }
                  onClick={() => navigate("/insurance_claimed")}
                >
                  Top achivers
                </div>
                <div
                  className={
                    currPath === "/packages" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/packages")}
                >
                  Packages
                </div>
                <div
                  className={
                    currPath === "/couponcount" ? "menu active-menu" : "menu"
                  }
                  onClick={() => navigate("/couponcount")}
                >
                  Coupon Count
                </div>
                <div
                  className="logout"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    zIndex: 100,
                   // marginTop: "auto"  // This pushes LOG OUT to the bottom
                  }}
                  onClick={logout}
                >
                  LOG OUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @media only screen and (max-width: 320px) {
          .logout {
            margin-top: auto;
          }
        }
      `}</style>
    </>
  );
};

export default Sidebar;
