import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import "../css/stylesnan.scss";
import Pagination from "@mui/material/Pagination";
import axios from "axios";

const Insurance = () => {
  const [members, setMembers] = useState([]);
  const [filePath, setFilePath] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async (start = "", end = "") => {
    try {
      const token = localStorage.getItem("user_token");
      const response = await axios.post(
        "https://lunarsenterprises.com:3006/tea/user/list",
        {
          format: "excel",
          start_date: start,
          end_date: end,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMembers(response.data.data); // Ensure that members is an array
      setFilePath(response.data.filepath); // Store the file path
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = () => {
    if (filePath) {
      window.open(filePath, "_blank");
    }
  };

  const handleSearch = () => {
    fetchList(startDate, endDate);
  };

  return (
    <Layout>
      <div className="text-center">
        <h1>Insurance Achievers</h1>
      </div>
      <div className="date-search">
        <div className="dates">
          <div className="start-date">
            <h5>From</h5>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="start-date">
            <h5>To</h5>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <button onClick={handleSearch}>Search</button>
      </div>
      <div className="mt-5" id="tab">
        <div className="table-responsive d-flex pe-3 ps-3 pb-0 bgWhite">
          <table
            className="table table-hover custom-table table-borderless"
            style={{ minWidth: "700px" }}
          >
            <thead className="bordertablesho">
              <tr className="line2px-green">
                <th scope="col">Sr No</th>
                <th scope="col">Name</th>
                <th scope="col">Aadhar Number</th>
                <th scope="col">Occupation</th>
              </tr>
            </thead>
            <tbody>
              {members?.map((item, index) => (
                <tr className="line1px" key={item.u_id}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.u_name}</td>
                  <td>{item.u_adhaar}</td>
                  <td>{item.u_occupation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="download-but">
        <button onClick={handleDownload}>Download</button>
      </div>
      <Pagination
        // count={totalPages}
        // page={currentPage}
        // onChange={handlePageChange}
        color="secondary"
        className="mt-3"
      />
    </Layout>
  );
};

export default Insurance;
